
































import { computed, defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'MBannerImages',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  setup(props: any, ctx: any) {
    const dialog = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    // const imageLength = ref(13);
    // const imagesArray = Array.from(Array(imageLength?.value).keys()).slice(1);
    // const images = ref();
    // images.value = imagesArray.map(
    //   img =>
    //     `https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage${img}.jpg`
    // );
    const images = ref([
      'https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage1.jpg',
      'https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage2.jpg',
      'https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage3.jpg',
      'https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage4.jpg',
      'https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage5.jpg',
      'https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage6.jpg',
      'https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage7.jpg',
      'https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage8.png',
      'https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage9.png',
      'https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage10.png',
      'https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage11.jpg',
      'https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage12.jpg',
      'https://s3.us-west-1.amazonaws.com/com.pilotcity.www.profile-image/program-images/programImage13.jpg'
    ]);
    const selectImage = imageVal => {
      ctx.emit('setSelctedImage', imageVal);
    };

    return {
      dialog,
      images,
      selectImage
    };
  }
});
